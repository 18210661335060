import React from "react";
import styled from "styled-components";

const ContentBlock = ({ title, content, isPageTitle } : { title: string, content?: string, isPageTitle: boolean }) => {

  const renderHeader = (isPageTitle:boolean, title:string) => {
    if (isPageTitle) return (<h1 className="title" dangerouslySetInnerHTML={{__html: title }} ></h1>)
    return (
      <h3 dangerouslySetInnerHTML={{__html: title }} ></h3> 
    )
  }


  return (
    <ContentBlockWrap className={`${isPageTitle ? 'is-title': ' '}`}>

      <div className="inner">
       {title && <div className="col">
          { renderHeader(isPageTitle, title) }
        </div> }
        {content && <div className="col">
          <div className="content" dangerouslySetInnerHTML={{__html: content }} />
        </div> }
      </div>

    </ContentBlockWrap>
  )
}

export default ContentBlock;

const ContentBlockWrap = styled.div`
  .title {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 30px 0;
    height: auto;
    width: 100%;
    letter-spacing: 0.1em;

    span {
      display: block;
      height: auto;
      width: 100%;
    }
  }

  .col {
    width: 100%;
  }

  .content {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    height: auto;
    width: 100%;
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    width: 90%;
    margin: 0 auto;
    
    .inner {
      display: flex;
      min-height: 28vw;
      width: 100%;
      box-sizing: border-box;
    }
    
    .col {
      box-sizing: border-box;
      overflow: hidden;

      &:first-child {
        width: 60%;
      }

      &:last-child {
        width: 40%;
        min-height: 16.875vw;
        padding-left: 10%;
        padding-top: 160px;
        padding-top: 11.11vw;
      }
    }

    .title {
      font-size: 100px;
      font-size: 6.94vw;
      line-height: 1.2;
      margin: 0;
    }

    .content {
      font-size: 1.25vw;
    }
  }
`
